import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Button, IconButton, Divider, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { getData, postData } from "../../../ServerRequest";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GridViewIcon from '@mui/icons-material/GridView';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';
import ImageIcon from '@mui/icons-material/Image';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ShuffleIcon from '@mui/icons-material/Shuffle';

const buttonStyle = { background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' } };
const sectionStyle = { backgroundColor: 'rgba(255, 255, 255, 0.6)', marginBottom: 2, boxShadow: '0px 4px 8px rgba(0, 0, 1, 0.1)' };
const collectionPages = [{ id: 1, title: 'Collection Page 1', url: '/collection-page-1' }, { id: 2, title: 'Collection Page 2', url: '/collection-page-2' }, { id: 3, title: 'Collection Page 3', url: '/collection-page-3' }];

export default function LandingPageBanner() {
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [formValues, setFormValues] = useState({ altText: '', title: '', subheading: '', ctaText: '', linkCollection: '', url: '', banner_image: '' });
    const [selectedBanner, setSelectedBanner] = useState(null);
    const [editingImageUrl, setEditingImageUrl] = useState('');
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [banners, setBanners] = useState([]);

    const handleBannerSubmit = async () => {
        let bannerReqData = {
            id: formValues.id ? formValues.id : '',
            image_alt_text: formValues.image_alt_text,
            title: formValues.title,
            subheading: formValues.subheading,
            cta_text: formValues.cta_text,
            link_page: formValues.link_page,
        };

        const convertToBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        };

        if (formValues.banner_image && formValues.banner_image instanceof Blob) {
            bannerReqData.banner_image = await convertToBase64(formValues.banner_image);
        } else if (isEditing && editingImageUrl) {
            bannerReqData.banner_image = editingImageUrl;
        }


        console.log(bannerReqData);

        try {
            let bannerResData = await postData('/api/theme/landing_banner', bannerReqData);
            if (bannerResData['data']['code'] === '200') {
                alert('Banner saved successfully.');
                if (isEditing) {
                    setBanners(banners.map(banner => banner.id === selectedBanner.id ? formValues : banner));
                } else {
                    const newBanner = { ...formValues, id: banners.length + 1 };
                    setBanners([...banners, newBanner]);
                }
                setIsFormVisible(false);
            } else {
                alert('Error saving banner. Please try again.');
            }
        } catch (error) {
            console.error('Error saving banner:', error);
        }
    };

    useEffect(() => {
        async function fetchBannerData() {
            let resData = await getData('/api/theme/landing_banner?is_admin=true');
            console.log(resData);
            let banners_data = resData['data']['banners'];
            if (banners_data) {
                console.log(banners_data);
                if (banners_data) {
                    setBanners(banners_data);
                }
            } else {
                console.error("Error fetching banner data:", resData.message);
            }
            setLoading(false);
        }

        fetchBannerData();
    }, []);

    const handleVisibilityToggle = async (id, newStatus) => {
        // Prepare request payload
        const visibilityReqData = {
            id, // Pass the banner ID
            status: newStatus ? 'enabled' : 'disabled', // Update the status
        };
    
        try {
            // Post request to update the banner status
            let visibilityResData = await postData('/api/theme/landing_banner?is_admin = true', visibilityReqData);
    
            // Handle response
            if (visibilityResData['data']['code'] === '200') {
                alert('Banner visibility updated successfully.');
                setBanners((prevBanners) =>
                    prevBanners.map((banner) =>
                        banner.id === id ? { ...banner, status: visibilityReqData.status } : banner
                    )
                );
            } else {
                alert('Error updating banner visibility. Please try again.');
            }
        } catch (error) {
            console.error('Error updating banner visibility:', error);
        }
    };  
    
    const shuffleArray = (array) => {
        // Fisher-Yates Shuffle Algorithm
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    const handleShuffle = async () => {
        // Filter out undefined entries before shuffling
        const filteredBanners = banners.filter((banner) => banner !== undefined);
    
        const shuffledBanners = shuffleArray(filteredBanners);
    
        setBanners(shuffledBanners); // Update the state with filtered and shuffled banners
    
        // Prepare data for backend update
        const shuffledBannerData = shuffledBanners.map((banner, index) => ({
            id: banner.id,
            order: index + 1, // Assuming the backend uses an 'order' field
        }));
    
        try {
            const response = await postData('/api/theme/landing_banner', { banners: shuffledBannerData });
            if (response.data.code === '200') {
                alert('Banners shuffled successfully.');
            } else {
                alert('Error saving shuffled banners.');
            }
        } catch (error) {
            console.error('Error shuffling banners:', error);
        }
    };    

    const handleAddNewBanner = (image) => {
        setFormValues({ altText: '', title: '', subheading: '', ctaText: '', linkCollection: '', url: '', banner_image: '' });
        setSelectedBanner(image);
        setIsFormVisible(true);
        setIsEditing(true);
    };

    const handleEditImage = (image) => {
        setFormValues(image);
        setSelectedBanner(image);
        setIsFormVisible(true);
        setIsEditing(true);
    };

    const handleDeleteImage = (id) => {
        setImages(images.filter((image) => image.id !== id));
    };

    const handleFormChange = (field, value) => {
        setFormValues((prev) => ({ ...prev, [field]: value }));
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const img = new Image();
            const imageUrl = URL.createObjectURL(file);
            img.src = imageUrl;
            img.onload = () => {
                if (img.width <= 1440 && img.height <= 300) {
                    setFormValues((prevState) => ({
                        ...prevState,
                        banner_image: file, // Store the file object
                        imageUrl: imageUrl  // Store the object URL for preview
                    }));
                } else {
                    alert('Image must be 1440px wide and 300px tall or smaller.');
                }
                URL.revokeObjectURL(imageUrl); // Free up memory
            };
            img.onerror = () => {
                alert('Failed to load image. Please try again.');
                URL.revokeObjectURL(imageUrl); // Free up memory
            };
        }
    };

    return (
        <Accordion sx={sectionStyle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <IconButton color="" component="icon">
                        <GridViewIcon />
                    </IconButton>
                    <Typography variant="h7">Hero Banner</Typography>
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ backgroundColor: '#eceff1', padding: 4, borderRadius: 1 }}>
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>Banner orientation</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: 1 }}>
                        {banners.length > 0 ? (
                            banners.map((image, index) => (
                                <Box key={image.id} sx={{ textAlign: 'center' }}>
                                    <img src={image.banner_image} alt={`Banner ${image.id}`} style={{ width: '140px', height: '60px', marginBottom: '10px', marginTop: '10px', filter: image.status === 'enabled' ? 'none' : 'blur(3px)', opacity: image.status === 'enabled' ? 1 : 0.6, transition: 'filter 0.3s ease-in-out' }} />
                                    {/*<Typography variant="body2">{image.title}</Typography>*/}
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <IconButton sx={{ mr: 0.5, color: 'grey' }} onClick={() => handleVisibilityToggle(image.id, image.status !== 'enabled')}>
                                            {image.status === 'enabled' ? (
                                                <VisibilityOutlinedIcon />
                                            ) : (
                                                <VisibilityOffOutlinedIcon />
                                            )}
                                        </IconButton>
                                        <IconButton sx={{ color: 'grey', mr: 0.5 }} onClick={() => handleEditImage(image)}><EditOutlinedIcon /></IconButton>
                                        <IconButton sx={{ color: 'red' }} onClick={() => handleDeleteImage(image.id)}><DeleteOutlinedIcon /></IconButton>
                                    </Box>
                                </Box>
                            ))
                        ) : (
                            <Typography>No banners added yet.</Typography>
                        )}
                    </Box>
                    {/*<IconButton onClick={handleShuffle} sx={{ ...buttonStyle, mt: 1, mb: 1 }}>
                        <ShuffleIcon /> 
                    </IconButton>*/}
                    <Box>
                        <Typography variant='caption'>Shuffle images to define 1-5 sequence</Typography>
                    </Box>
                    {/*<IconButton variant='contained' sx={{ ...buttonStyle, mt: 2 }} onClick={handleAddNewBanner}><AddIcon /></IconButton>*/}
                </Box>
                {isFormVisible && (
                    <>
                        <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
                        <TextField fullWidth label="Name / Image Alt Text" variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 3 }} value={formValues.image_alt_text} onChange={(e) => handleFormChange('image_alt_text', e.target.value)} />
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
                            <Typography sx={{ marginLeft: 5, marginRight: 5 }}>
                                {formValues.banner_image || isEditing ? (
                                    <figure>
                                    <img alt="Preview" src={formValues.banner_image || (isEditing ? editingImageUrl : null)} style={{ width: '350px', height: '150px', border: '1px solid #ccc', marginBottom: 2 }} />
                                    <figcaption>W 1440px / H 300px</figcaption>
                                    </figure>
                                ) : (
                                    <Box sx={{ width: '350px', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #ccc' }}>
                                        <ImageIcon sx={{ fontSize: 60, color: '#ccc' }} />
                                    </Box>
                                )}
                            </Typography>
                            <IconButton component="label" sx={{ color: 'black' }}>
                                <FileUploadOutlinedIcon />
                                <input hidden accept="image/*" type="file" onChange={(e) => handleImageUpload(e)} />
                            </IconButton>
                        </Box>
                        <Divider sx={{ marginBottom: 2 }} />
                        <TextField fullWidth label="Title" placeholder="Write title text here" variant="outlined" margin="normal" helperText="Max 20 characters including space" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 2 }} value={formValues.title} onChange={(e) => handleFormChange('title', e.target.value)} />
                        <TextField fullWidth label="Subheading" placeholder="Write text here" variant="outlined" margin="normal" helperText="Max 40 characters including space" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 2 }} value={formValues.subheading} onChange={(e) => handleFormChange('subheading', e.target.value)} />
                        <TextField fullWidth label="CTA Text" placeholder="Type your text here" variant="outlined" margin="normal" helperText="Max 10 characters including space" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 4 }} value={formValues.cta_text} onChange={(e) => handleFormChange('cta_text', e.target.value)} />
                        <FormControl fullWidth sx={{ marginBottom: 3 }}>
                            <InputLabel id="link-collection-label">Link Collection Page</InputLabel>
                            <Select labelId="link-collection-label" label="Link Collection Page"  InputLabelProps={{ shrink: true }} value={formValues.link_page} onChange={(e) => handleFormChange('link_page', e.target.value)}>
                                {collectionPages.map((page) => (
                                    <MenuItem key={page.id} value={page.url}>{page.title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Divider sx={{ marginBottom: 2 }}>
                            <Typography sx={{ padding: '0 10px' }}>or</Typography>
                        </Divider>
                        <TextField fullWidth label="URL Link to the Collection Page" placeholder="URL" variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} value={formValues.link_page} onChange={(e) => handleFormChange('link_page', e.target.value)} />
                        <Button onClick={handleBannerSubmit} sx={{ ...buttonStyle, mt: 2 }}>
                          Save
                        </Button>
                    </>
                )}
            </AccordionDetails>
        </Accordion>
    );
}


