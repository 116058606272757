import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { Grid, Button, Typography, Modal, TextField, IconButton, Link } from '@mui/material';
import { getData, postData } from "../../ServerRequest";
import { Box, Container } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import AddIcon from '@mui/icons-material/Add';
import { Pagination } from '@mui/material';

export default function ProductDetails() {
    const [modalOpen, setModalOpen] = useState(false);
    const [productDetails, setProductList] = useState({
        productName: '',
        description: '',
        price: ''
    });
    const [productsData, setProductsData] = useState([]);

    const [pagination, setPagination] = useState({ current_page: 1, per_page: 10, total: 0 });
    const [loading, setLoading] = useState(true);
    const [productCount, setProductCount] = useState(0);

    const fetchProducts = async (page = 1) => {
        setLoading(true);
        try {
            const resData = await getData(`/api/product/all?page=${page}&per_page=${pagination.per_page}`);
            console.log("API Response:", resData); // Debug response
            const { products = [], pagination: pag = { total_product:0, total: 0, num_pages: 1, current_page: 1, per_page: 10 } } = resData.data;
    
            setProductsData(products);
            setPagination(pag);
        } catch (error) {
            console.error("Error fetching products:", error);
            setPagination({ total: 0, num_pages: 1, current_page: 1, per_page: 10 });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    const handlePageChange = (event, value) => {
        fetchProducts(value); // Fetch data for the selected page
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setProductList((prevDetails) => ({
            ...prevDetails,
            [name]: value
        }));
    };

    const handleFormSubmit = () => {
        console.log('Product Details:', productDetails);
        setModalOpen(false);
    };

    const columns = [
        {
            name: 'ps_product_productcode',
            label: 'Product Code',
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value) => (
                    <Link style={{ color: '#0074cd', textDecoration: 'none' }} href={'/admin/products/productDetail?product_id=' + value}>
                        {value}
                    </Link>
                ),
            }
        },
        {
            name: 'ps_product_cat',
            label: 'Product category',
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: 'ps_product_productcode',
            label: 'Product code',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: 'ps_product_netqty',
            label: 'Net Qty',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: 'ps_product_productimg',
            label: 'Product Image',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: 'ps_product_purchaserate1',
            label: 'Product purchase rate',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: 'ps_product_sellrate1',
            label: 'Product sell rate',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
    ];

    const options = {
        filterType: 'checkbox',
        responsive: 'standard',
        serverSide: true, // Enable server-side pagination
        count: pagination?.total_product || 0, // Set the total count of items
        page: pagination?.current_page - 1, // MUI uses zero-based indexing for pages
        rowsPerPage: pagination?.per_page,
        onChangePage: (currentPage) => {
            fetchProducts(currentPage + 1); // Convert zero-based index to one-based index
        },
        onChangeRowsPerPage: (rowsPerPage) => {
            setPagination((prev) => ({ ...prev, per_page: rowsPerPage }));
            fetchProducts(1); // Reset to the first page when per_page changes
        },
        customToolbar: () => (
            <IconButton onClick={() => setModalOpen(true)} sx={{ background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' }, mb: 0 }}>
                <AddIcon />
            </IconButton>
        ),
        textLabels: {
            body: {
                noMatch: loading ? "Loading..." : "Sorry, no matching records found"
            }
        }
    };

    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', marginLeft: 10, marginTop: 2 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <MUIDataTable
                        title={'Product List'}
                        data={loading ? [] : productsData}
                        columns={columns}
                        options={options}
                    />
                </Box>
            </Grid>
            {/* <Pagination
                count={pagination.num_pages}
                page={pagination.current_page}
                onChange={handlePageChange}
                color="primary"
                sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}
            /> */}
            {/* Modal for adding new product */}
            <Modal open={modalOpen} onClose={handleModalClose} aria-labelledby="add-product" aria-describedby="add-a-new-product">
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 6, borderRadius: 2 }}>
                    <Typography id="add-product" variant="h6" component="h2" sx={{ mb: 3 }}>
                        Add New Product
                    </Typography>
                    <TextField label="Product Name" fullWidth variant="outlined" name="productName" value={productDetails.productName} onChange={handleInputChange} sx={{ mb: 3 }} />
                    <TextField label="Description" fullWidth variant="outlined" name="description" value={productDetails.description} onChange={handleInputChange} sx={{ mb: 3 }} />
                    <TextField label="Price" fullWidth variant="outlined" name="price" value={productDetails.price} onChange={handleInputChange} sx={{ mb: 3 }} />
                    <Button variant="contained" sx={{ backgroundColor: '#417690', '&:hover': { backgroundColor: '#5A8EA8' } }} style={{ color: '#fff' }} onClick={handleFormSubmit}>
                        Submit
                    </Button>
                </Box>
            </Modal>

        </Container>
    );
};
