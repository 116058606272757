import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Button, IconButton, TextField, MenuItem, Autocomplete } from '@mui/material';
import { getData, postData } from "../../../ServerRequest";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GridViewIcon from '@mui/icons-material/GridView';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

const buttonStyle = { background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' } };
const sectionStyle = { backgroundColor: 'rgba(255, 255, 255, 0.6)', marginBottom: 2, boxShadow: '0px 4px 8px rgba(0, 0, 1, 0.1)' };

export default function LandingPagePromotionalBanner() {
    const [loading, setLoading] = useState(false);
    const [promoBanner, setPromoBanner] = useState([]);
    const [previewImage, setPreviewImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [collectionOptions, setCollectionOptions] = useState([]);

    const handlePromoBannerSubmit = async (index) => {
        console.log(promoBanner[index]);
        let promoBannerReqData = {
            'id': promoBanner[index].id ? promoBanner[index].id : '',
            'promotional_banner_image': promoBanner[index].imageUrl ? promoBanner[index].imageUrl : previewImage,
            'page_link': promoBanner[index].linkUrl ? promoBanner[index].linkUrl : '',
        };

        let promoBannerResData = await postData('/api/theme/landing_promotional', promoBannerReqData);
        if (promoBannerResData['data']['code'] !== 200) {
            alert('Promo banner data saved successfully');
            setLoading(false);
            return true;
        }
        else {
            alert('Error saving promo banner data');
            setLoading(false);
        }
        return false;
    };

    useEffect(() => {
        async function fetchPromoBannerData() {
            let resData = await getData('/api/theme/landing_promotional');
            console.log(resData);
            let promo_data = resData['data']['promo_banners']
            if (promo_data) {
                console.log(promo_data);
                if (promo_data) {
                    setPromoBanner(promo_data);
                }
            } else {
                console.error("Error fetching banner data:", resData.message);
            }
            setLoading(false);
        }

        fetchPromoBannerData();
    }, []);

    const handleFileUpload = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
                promoBanner[index].promotional_banner_image = reader.result;
                setSelectedFile(file);
            };
            reader.readAsDataURL(file);
        }
    };

    const addPromoBanner = () => {
        const newBanner = { id: null, promotional_banner_image: '', page_link: '' }; // Initialize a new empty banner
        setPromoBanner([...promoBanner, newBanner]); // Add the new banner to the existing array
    };

    const deletePromoBanner = (index) => {
        const updatedPromoBanner = [...promoBanner];
        updatedPromoBanner.splice(index, 1);
        setPromoBanner(updatedPromoBanner);
    };

    return (
        <Accordion sx={sectionStyle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconButton color="" component="icon">
                    <GridViewIcon />
                </IconButton>    
                <Typography variant="h7">Promotion Banner</Typography>
            </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {loading ? (
                    <Typography>Loading...</Typography>
                ) : (
                    <>
                        {promoBanner.map((item, index) => (
                            <Box key={item.id} sx={{ background: '#eceff1', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2, borderRadius: 1, position: 'relative' }}>
                                {/*<IconButton sx={{ color: 'red', position: 'absolute', top: 8, right: 8 }} onClick={() => deletePromoBanner(index)}>
                                    <DeleteOutlinedIcon />
                                </IconButton>*/}
                                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography key={index} sx={{ marginLeft: 5, marginRight: 5, mt: 1 }}>
                                        <figure>
                                        <img src={item.promotional_banner_image || previewImage} alt="Promotion Banner" style={{ width: '400px', height: '130px', border: '1px solid #ccc', marginBottom: 2 }} />
                                        <figcaption>W 1440px / H 200px</figcaption>
                                        </figure>
                                    </Typography>
                                    <IconButton sx={{ color: 'black' }} component="label">
                                        <FileUploadOutlinedIcon />
                                        <input hidden accept="image/*" type="file" onChange={(e) => handleFileUpload(e, index)} />
                                    </IconButton>
                                </div>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Autocomplete
                                        options={[{ label: "None", value: "none" }, ...collectionOptions]}
                                        getOptionLabel={(option) => option.label}
                                        value={collectionOptions.find(option => option.value === item.page_link) || null}
                                        onChange={(event, newValue) => {
                                            const updatedPromoBanner = [...promoBanner];
                                            updatedPromoBanner[index].page_link = newValue?.value || '';
                                            setPromoBanner(updatedPromoBanner);

                                            // If "None" is selected, clear the linked_url and enable the text field
                                            if (newValue?.value === 'none') {
                                                updatedPromoBanner[index].linked_url = ''; // Clear the URL when "None" is selected
                                            }
                                            setPromoBanner(updatedPromoBanner);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Link collection page"
                                                fullWidth
                                                sx={{
                                                    width: { xs: '100%', sm: '100%', md: '800px' },
                                                    marginBottom: 2, mr: 11,
                                                    '& .MuiInputBase-root': { backgroundColor: 'white' },
                                                }}
                                            />
                                        )}
                                    />  
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 1 }}>
                                <TextField
                                    fullWidth
                                    label="URL to link collection page"
                                    placeholder="URL"
                                    variant="outlined"
                                    margin="normal"
                                    sx={{ width: { xs: '100%', sm: '100%', md: '800px' }, marginBottom: 2, '& .MuiInputBase-root': { backgroundColor: 'white' } }}
                                    value={item.page_link}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        const updatedPromoBanner = [...promoBanner];
                                        updatedPromoBanner[index].page_link = e.target.value;
                                        setPromoBanner(updatedPromoBanner); 
                                    }}
                                    disabled={item.page_link !== 'none'} // Disable if "None" is not selected
                                />
                                <Button onClick={() => handlePromoBannerSubmit(index)} sx={{ ...buttonStyle, mb: 2, mt: 2, ml: 1 }}>
                                    Save
                                </Button>
                                </Box>
                            </Box>
                        ))}
                        {/*<IconButton variant="contained" onClick={addPromoBanner} sx={{ ...buttonStyle }}>
                            <AddIcon />
                        </IconButton>*/}
                    </>
                )}

            </AccordionDetails>
        </Accordion>

    );
}


