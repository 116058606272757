import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Button, IconButton, TextField, MenuItem, Autocomplete } from '@mui/material';
import { getData, postData } from "../../../ServerRequest";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GridViewIcon from '@mui/icons-material/GridView';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

const buttonStyle = { background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' } };
const sectionStyle = { backgroundColor: 'rgba(255, 255, 255, 0.6)', marginBottom: 2, boxShadow: '0px 4px 8px rgba(0, 0, 1, 0.1)' };

export default function LandingPageMoodBoard() {
    const [loading, setLoading] = useState(false);
    const [moodboardData, setMoodboardData] = useState([]);
    const [previewImage, setPreviewImage] = useState(null);
    const [collectionOptions, setCollectionOptions] = useState([]);

    const handleMoodboardSubmit = async (index) => {
        let item = moodboardData[index];
        let moodboardReqData = {
            'id': item.id ? item.id : '',
            'moodboard_image': item.moodboard_image ? item.moodboard_image : '',
            'linked_url': item.linked_url ? item.linked_url : ''
        };


        let moodboardResData = await postData('/api/theme/landing_moodboard', moodboardReqData);
        if (moodboardResData['data']['code'] !== 200) {
            alert('Moodboard data saved successfully');
            setLoading(false);
            return true;
        }
        else {
            alert('Error saving moodboard data');
            setLoading(false);
        }
        return false;
    };

    useEffect(() => {
        async function fetchMoodboardData() {
            let resData = await getData('/api/theme/landing_moodboard');
            console.log(resData);
            let banners_data = resData['data']['mood_boards'];
            if (banners_data) {
                console.log(banners_data);
                if (banners_data) {
                    setMoodboardData(banners_data);
                }
            } else {
                console.error("Error fetching moodboard data:", resData.message);
            }
            setLoading(false);
        }

        fetchMoodboardData();
    }, []);

    const handleFileUpload = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
                moodboardData[index].moodboard_image = reader.result;
               
            };
            reader.readAsDataURL(file);
        }
    };

    const addMoodboard = () => {
        const newMoodboard = { id: null, moodboard_image: '', linked_url: '' }; 
        setMoodboardData([...moodboardData, newMoodboard]); 
    };

    const deleteMoodboard = (index) => {
        const updatedMoodboard = moodboardData.filter((_, i) => i !== index);
        setMoodboardData(updatedMoodboard);
    };

    return (
        <Accordion sx={sectionStyle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconButton color="" component="icon">
                    <GridViewIcon />
                </IconButton>
                <Typography variant="h7">Moodboard</Typography>
            </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {loading ? (    
                    <Typography>Loading...</Typography>
                ) : (
                    <>
                        {moodboardData.map((item, index) => (
                           <Box key={item.id} sx={{ background: '#eceff1', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2, borderRadius: 1, position: 'relative' }}>
                                {/*<IconButton sx={{ color: 'red', position: 'absolute', top: 8, right: 8 }} onClick={() => deleteMoodboard(index)}>
                                    <DeleteOutlinedIcon />
                                </IconButton>*/}
                                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography key={index} sx={{ marginLeft: 5, marginRight: 5, mt: 1 }}>
                                        <figure>
                                        <img src={item.moodboard_image || previewImage} alt="Moodboard" style={{ width: '400px', height: '130px', border: '1px solid #ccc', marginBottom: 2 }} />
                                        <figcaption>W 1000px / H 800px</figcaption>
                                        </figure>
                                    </Typography>
                                    <IconButton sx={{ color: 'black' }} component="label">
                                        <FileUploadOutlinedIcon />
                                        <input hidden accept="image/*" type="file" onChange={(e) => handleFileUpload(e, index)} />
                                    </IconButton>
                                </div>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Autocomplete
                                        options={[{ label: "None", value: "none" }, ...collectionOptions]}
                                        getOptionLabel={(option) => option.label}
                                        value={collectionOptions.find(option => option.value === item.page_link) || null}
                                        onChange={(event, newValue) => {
                                            const updatedMoodboard = [...moodboardData];
                                            updatedMoodboard[index].page_link = newValue?.value || '';
                                            setMoodboardData(updatedMoodboard);

                                            // If "None" is selected, clear the linked_url and enable the text field
                                            if (newValue?.value === 'none') {
                                                updatedMoodboard[index].linked_url = ''; // Clear the URL when "None" is selected
                                            }
                                            setMoodboardData(updatedMoodboard);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Link collection page"
                                                fullWidth
                                                sx={{
                                                    width: { xs: '100%', sm: '100%', md: '800px' },
                                                    marginBottom: 2, mr: 12,
                                                    '& .MuiInputBase-root': { backgroundColor: 'white' },
                                                }}
                                            />
                                        )}
                                    />    
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 1 }}>
                                <TextField
                                    fullWidth
                                    label="URL to link collection page"
                                    placeholder="URL"
                                    variant="outlined"
                                    margin="normal"
                                    sx={{ width: { xs: '100%', sm: '100%', md: '800px' }, marginBottom: 2, '& .MuiInputBase-root': { backgroundColor: 'white' } }}
                                    value={item.linked_url}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        const updatedMoodboard = [...moodboardData];
                                        updatedMoodboard[index].linked_url = e.target.value;
                                        setMoodboardData(updatedMoodboard); // Correctly update the specific Moodboard object
                                    }}
                                    disabled={item.page_link !== 'none'} // Disable if "None" is not selected
                                />
                                <Button variant="contained" onClick={() => handleMoodboardSubmit(index)} sx={{ ...buttonStyle, mb: 2, mt: 2, ml: 1 }}>
                                    Save
                                </Button>
                            </Box>
                            </Box>
                        ))}
                        {/*<IconButton variant="contained" onClick={addMoodboard} sx={{ ...buttonStyle }}>
                            <AddIcon />
                        </IconButton>*/}    
                    </>
                )}
               
            </AccordionDetails>
        </Accordion>
    );
}


