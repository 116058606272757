import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { Grid, IconButton, Link } from '@mui/material';
import { getData, postData } from "../../ServerRequest";
import { Box, Container } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import AddIcon from '@mui/icons-material/Add';

export default function ProductCollection (){   
    const [collectionsData, setCollectionsData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchcollectionsData() {
            let resData = await getData('/api/product/product-collection');
            console.log(resData);
            let collections_data = resData['data']['collections']
            if (collections_data) {
                console.log(collections_data);
                if (collections_data) {
                    setCollectionsData(collections_data);
                }
            } else {
                console.error("Error fetching collections data:", resData.message);
            }
            setLoading(false);
        }

        fetchcollectionsData();
    }, []); 

    const columns = [
        {
            name: 'id',
            label: 'Collection ID',
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value) => (
                    <Link style={{ color: '#0074cd', textDecoration: 'none' }} href={'/admin/products/collectionDetail?collection_id=' + value}>
                        {value}
                    </Link>
                ),
            }
        },
        {
            name: 'collection',
            label: 'Collection',
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
    ];

    const options = {
        filterType: 'checkbox',
        responsive: 'standard',
        tableBodyHeight: 'calc(100vh - 64px - 48px)',
        tableBodyMaxHeight: 'calc(100vh - 64px - 48px)',
        customToolbar: () => (
                <IconButton sx={{ background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' }, mb:0 }}>
                    <AddIcon />
                </IconButton>
            ),
            textLabels: {
                body: {
                    noMatch: loading ? "Loading..." : "Sorry, no matching records found"
                }
            }
    };

    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', marginLeft: 10, marginTop: 2 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <MUIDataTable
                        title={'Collection'}
                        data={loading ? [] : collectionsData}
                        columns={columns}
                        options={options}
                    />
                </Box>
            </Grid>
        </Container>
    );
};
