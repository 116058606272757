import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button, IconButton, Divider, TextField, Avatar, Box, MenuItem, Autocomplete } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getData, postData, deleteData } from "../../../ServerRequest";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GridViewIcon from '@mui/icons-material/GridView';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

const buttonStyle = { background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' } };
const sectionStyle = { backgroundColor: 'rgba(255, 255, 255, 0.6)', marginBottom: 2, boxShadow: '0px 4px 8px rgba(0, 0, 1, 0.1)' };

export default function LandingPageIcons() {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({ id: '', title: '', icon_image: '', page_link: '', orientation_number: '' });
    const [iconTiles, setIconTiles] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [collectionOptions, setCollectionOptions] = useState([]);

    useEffect(() => {
        async function fetchIconData() {
            let resData = await getData('/api/theme/landing_icon?is_admin=true');
            console.log(resData);
            let icons_data = resData['data']['title_icons']
            if (icons_data) {
                console.log(icons_data);
                if (icons_data) {
                    setIconTiles(icons_data);
                }
            } else {
                console.error("Error fetching icons data:", resData.message);
            }
            setLoading(false);
        }

        fetchIconData();
    }, []);

    const handleToggle = () => {
        setIsVisible((prev) => !prev);
    };

    const handleSaveTile = async (index) => {
        console.log(iconTiles[index]);
        
        let iconReqData = {
            id: iconTiles[index].id ? iconTiles[index].id : '',
            icon_image: iconTiles[index].icon_image,
            title: iconTiles[index].title,
            page_link: iconTiles[index].page_link,
            orientation_number: iconTiles[index].orientation_number,
            status: iconTiles[index].status?iconTiles[index].status:'',
        };

        const convertToBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        };

        if (iconTiles[index].icon_imagee && iconTiles[index].icon_image instanceof Blob) {
            iconReqData.icon_image = await convertToBase64(iconTiles[index].icon_image);
        }
        // else if (isEditing && editingImageUrl) {
        //     iconReqData.icon_image = editingImageUrl;
        // }


        console.log(iconReqData);

        try {
            let bannerResData = await postData('/api/theme/landing_icon', iconReqData);
            if (bannerResData['data']['code'] === '200') {
                alert('Icon saved successfully.');
                
            } else {
                alert('Error in saving icon. Please try again.');
            }
        } catch (error) {
            console.error('Error in saving icon:', error);
        }
    };

    const handleVisibilityToggle = async (id, newStatus) => {
        const visibilityReqData = {
            id, 
            status: newStatus ? 'enabled' : 'disabled',
        };
    
        try {
            let visibilityResData = await postData('/api/theme/landing_icon?is_admin = true', visibilityReqData);
    
            if (visibilityResData['data']['code'] === '200') {
                alert('Tile visibility updated successfully.');
                setIconTiles((prevTiles) =>
                    prevTiles.map((tile) =>
                        tile.id === id ? { ...tile, status: visibilityReqData.status } : tile
                    )
                );
            } else {
                alert('Error updating tile visibility. Please try again.');
            }
        } catch (error) {
            console.error('Error updating tile visibility:', error);
        }
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const img = new Image();
            const imageUrl = URL.createObjectURL(file);
            img.src = imageUrl;
            img.onload = () => {
                if (img.width <= 1440 && img.height <= 300) {
                    setFormValues((prevState) => ({
                        ...prevState,
                        banner_image: imageUrl,
                        imageUrl: file
                    }));
                } else {
                    alert('Image must be 1440px wide and 300px tall or smaller.');
                }
            };
        }
    };

    const handleTitleChange = (e, index) => {
        const newTiles = [...iconTiles];
        newTiles[index].title = e.target.value;
        setIconTiles(newTiles);
        console.log(iconTiles);
        
    };

    const handleURLChange = (e, index) => {
        const newTiles = [...iconTiles];
        newTiles[index].page_link = e.target.value;
        setIconTiles(newTiles);
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(iconTiles);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setIconTiles(items);
    };

    const handleEditTile = (e, index) => {
        e.stopPropagation();
        setEditingIndex(index);
    };

    const handleDeleteTile = (e, index) => {
        e.stopPropagation();
        const updatedTiles = iconTiles.filter((_, i) => i !== index);
        setIconTiles(updatedTiles);
    };

    const handleAddTile = () => {
        const newTile = { id: '', title: '', icon_image: '', page_link: '', orientation_number: '' };
        setIconTiles([...iconTiles, newTile]);
    };

    return (

        <Accordion sx={sectionStyle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconButton color="" component="icon">
                    <GridViewIcon />
                </IconButton>
                <Typography variant="h7">Icon Tiles</Typography>
            </Typography>    
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ display: 'flex' }}>
                    <TextField fullWidth label="Section Title" placeholder="Add section title here" helperText="Max 20 characters including space" variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 2 }} />
                    <IconButton sx={{ mr: 1, color: 'grey', mb: 3, mt: 0}} onClick={(e) => {handleEditTile(e); handleToggle();}}>
                        {isVisible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                    </IconButton>
                </div>
                <Divider sx={{ marginBottom: 3 }} />
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="iconTiles">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {iconTiles.length > 0 ? (
                                    iconTiles.map((tile, index) => (
                                        <Draggable key={tile.id} draggableId={tile.id.toString()} index={index}>
                                            {(provided, snapshot) => (
                                                <Accordion ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} sx={{ backgroundColor: '#eceff1', marginBottom: 2, ...provided.draggableProps.style, borderRadius: 1 }}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                            <MenuIcon sx={{ cursor: 'pointer', marginRight: 2 }} />
                                                            <Avatar src={tile.icon_image} sx={{ width: 40, height: 40, backgroundColor: 'white', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 2 }} />
                                                            <Typography>Icon title {index + 1} {tile.title}</Typography>
                                                        </Typography>
                                                        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                                                            <IconButton sx={{ mr: 1, color: 'grey' }} onClick={() => handleVisibilityToggle(tile.id, tile.status !== 'enabled')}>
                                                                {tile.status === 'enabled' ? (
                                                                    <VisibilityOutlinedIcon />
                                                                ) : (
                                                                    <VisibilityOffOutlinedIcon />
                                                                )}
                                                            </IconButton>
                                                            <IconButton sx={{ color: 'red' }} onClick={(e) => handleDeleteTile(e, index)}><DeleteOutlinedIcon /></IconButton>
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails>                                                    
                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                                            <Avatar src={tile.icon_image} sx={{ width: 100, height: 100, backgroundColor: 'white', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 2, marginLeft: 3 }} />
                                                            <div>
                                                                <IconButton variant="outlined" component="label" sx={{ mt: 2, color: 'black'  }}>
                                                                    <FileUploadOutlinedIcon />
                                                                    <input type="file" hidden accept="image/png" onChange={(e) => handleImageUpload(e, index)} />
                                                                </IconButton>
                                                                <Typography variant="caption" display="block" align="center">
                                                                    W 96px / H 96px
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <Typography variant="caption" display="block" sx={{ marginTop: 1, marginBottom: 1 }}>
                                                            *Only PNG with transparent background
                                                        </Typography>
                                                        <TextField fullWidth label="Title" placeholder="Write title text here" helperText="Max 12 characters including space" variant="outlined" margin="normal" sx={{ width: { xs: '100%', sm: '100%', md: '800px' }, '& .MuiInputBase-root': { backgroundColor: 'white' } }} InputLabelProps={{ shrink: true }} value={tile.title} onChange={(e) => handleTitleChange(e, index)} />
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <Autocomplete
                                                                options={[{ label: "None", value: "none" }, ...collectionOptions]}
                                                                getOptionLabel={(option) => option.label}
                                                                value={collectionOptions.find(option => option.value === tile.page_link) || null}
                                                                onChange={(event, newValue) => {
                                                                    const updatedIconTiles = [...iconTiles];
                                                                    updatedIconTiles[index].page_link = newValue?.value || '';
                                                                    setIconTiles(updatedIconTiles);

                                                                    // If "None" is selected, clear the linked_url and enable the text field
                                                                    if (newValue?.value === 'none') {
                                                                        updatedIconTiles[index].linked_url = ''; // Clear the URL when "None" is selected
                                                                    }
                                                                    setIconTiles(updatedIconTiles);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Link collection page"
                                                                        fullWidth
                                                                        sx={{
                                                                            width: { xs: '100%', sm: '100%', md: '800px' },
                                                                            marginBottom: 2, mr: 11,
                                                                            '& .MuiInputBase-root': { backgroundColor: 'white' },
                                                                        }}
                                                                    />
                                                                )}
                                                            />    
                                                        </Box>
                                                        <TextField fullWidth label="URL to link collection page" placeholder="URL" variant="outlined" margin="normal" sx={{ width: { xs: '100%', sm: '100%', md: '800px' }, marginBottom: 1, '& .MuiInputBase-root': { backgroundColor: 'white' } }} InputLabelProps={{ shrink: true }} value={tile.page_link} onChange={(e) => handleURLChange(e, index)} disabled={tile.page_link !== 'none'} />
                                                        
                                                            <Button onClick={() => handleSaveTile(index)} sx={{ ...buttonStyle, mt: 3, ml: 18 }}>
                                                                Save
                                                            </Button> 
                                                    </AccordionDetails>
                                                </Accordion>
                                            )}
                                        </Draggable>
                                    ))
                                ) : (
                                    <Typography>No icon tiles available</Typography>
                                )}
                                {provided.placeholder}
                                {/* Add Button for adding new tiles */}
                                <IconButton variant="contained" sx={{ ...buttonStyle, mt: 1 }} onClick={handleAddTile}>
                                    <AddIcon />
                                </IconButton>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </AccordionDetails>
        </Accordion>

    );
}


